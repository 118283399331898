import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";

import { MaterialModule } from "./material.module";
import { SpinnerComponent } from "./spinner/spinner";

const componnets = [
    SpinnerComponent
]

@NgModule({
    declarations: [
        ...componnets
    ],
    imports: [
        CommonModule,
        FormsModule,
        FontAwesomeModule,
        MaterialModule,
        RouterModule
    ],
    exports: [
        ...componnets
    ]
})
export class ComponentsModule {}