import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgsRevealModule } from 'ngx-scrollreveal';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { ContractService } from 'src/_services/contract.service';
import { ComponentsModule } from 'src/_components/components.module';
import { ModalsModule } from 'src/_modals/modals.module';
import { PipesModule } from 'src/_pipes/pipes.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { AlertController } from 'src/_services/alert.service';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    NgsRevealModule,
    FontAwesomeModule,
    ComponentsModule,
    ModalsModule,
    PipesModule,
    HttpClientModule,
    BrowserAnimationsModule
  ],
  providers: [
    ContractService,
    AlertController,
    { provide: 'Window',  useValue: window },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas);
  }
}
