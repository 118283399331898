import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { AlertComponent } from "src/_modals/alert/alert.component";

@Injectable()
export class AlertController {
    constructor(private dialog: MatDialog) {

    }
    
    async create(options: AlertOptions) {
        return new Alert(this, this.dialog, options);
    }

}



class Alert {
    ref?: MatDialogRef<AlertComponent>;
    id: string;

    constructor(private parent: AlertController, private dialog: MatDialog, private options: AlertOptions) {
        this.id = Math.round(Math.random() * new Date().getTime()).toString(16);
    }

    present() {
        this.ref = this.dialog.open<AlertComponent, any, AlertOptions>(AlertComponent, {
            data: this.options,
            disableClose: this.options.backdropDismiss,
            panelClass: ['alert-modal', this.options.cssClass ? this.options.cssClass : '']
        })
    }

    dismiss() {
        if(this.ref) this.ref.close();
    }
}


export interface AlertOptions {
    cssClass?: string,
    header: string,
    message: string,
    buttons?: AlertButton[],
    backdropDismiss?: boolean
}

export interface AlertButton {
    text: string;
    role?: 'cancel' | 'confirm';
    handler?: () => void;
}