import { NgModule } from "@angular/core";
import { USDPipe } from "./usd.pipe";

@NgModule({
    declarations: [
        USDPipe
    ],
    imports: [], 
    exports: [
        USDPipe
    ]
})
export class PipesModule {

}