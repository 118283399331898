import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { ComponentsModule } from "src/_components/components.module";
import { MaterialModule } from "src/_components/material.module";
import { AlertComponent } from "./alert/alert.component";

const modals = [
    AlertComponent
]

@NgModule({
    declarations: [
        ...modals
    ],
    imports: [
        FormsModule,
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        MaterialModule,
        ComponentsModule,
        FontAwesomeModule,
    ],
    exports: [
        ...modals
    ]
})
export class ModalsModule { }