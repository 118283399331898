import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from "@angular/material/icon";
import { MatSnackBarModule } from "@angular/material/snack-bar";
 
@NgModule({
    declarations: [
        
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatDialogModule,
        MatInputModule,
        MatIconModule,
        MatSnackBarModule
    ],
    exports: [
        MatDialogModule,
        MatInputModule,
        MatIconModule,
        MatSnackBarModule
    ]
})
export class MaterialModule {}