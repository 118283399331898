<div class="card">
    <div class="card-header">
        <h4>{{ header }}</h4>
    </div>
    <div class="card-body" [innerHTML]="message"></div>
    <div class="card-footer" *ngIf="buttons && buttons.length">
        <ng-container *ngFor="let btn of buttons">
            <a class="btn" [ngClass]="{'primary': !btn.role || btn.role == 'confirm', 'danger': btn.role && btn.role == 'cancel'}" (click)="handleButtonClick(btn)">
                {{btn.text}}
            </a>
        </ng-container>
    </div>
</div>