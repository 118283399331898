import { Inject, Injectable } from '@angular/core';
import Web3 from 'web3';
import { Contract } from 'web3-eth-contract';
import detectEthereumProvider from '@metamask/detect-provider';
import { BlockHeader } from 'web3-eth';
import { Subscription } from 'web3-core-subscriptions';
import { AbiItem } from 'web3-utils';
import {
  MatSnackBar,
  MatSnackBarRef,
  TextOnlySnackBar,
} from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';

import * as abijson from '../assets/abi.json';

const network_names: { [key: number]: string } = {
  1: 'Mainnet',
  4: 'Rinkeby',
};
const network_explorer: { [key: number]: string } = {
  1: 'https://etherscan.io/',
  4: 'https://rinkeby.etherscan.io/',
};

@Injectable()
export class ContractService {
  private events: any = {};
  private _accounts: string[] = [];
  provider?: any;
  web3?: Web3;
  public contract?: Contract;
  private contract_json?: ContractJson;
  private _balance: string = '';
  private logSubscription?: Subscription<BlockHeader>;

  public pendingTrx?: MatSnackBarRef<TextOnlySnackBar>;
  public pendingData: any;
  private connected_chain?: number;

  web3_error?: string;
  permissions = {
    is_admin: false,
    is_pauser: false,
  };

  private account_loading: boolean = false;
  private contract_loading: boolean = false;
  private permission_loading: boolean = false;

  get accounts(): string[] {
    return this._accounts;
  }

  get loading(): boolean {
    return (
      this.account_loading || this.contract_loading || this.permission_loading
    );
  }

  get connected(): boolean {
    return this._accounts.length > 0;
  }

  get chain_matching(): boolean {
    return this.connected_chain
      ? this.connected_chain == environment.mint_network
      : false;
  }

  constructor(
    private http: HttpClient,
    private toast: MatSnackBar,
    @Inject('Window') private window: Window
  ) {
    // this.connect();
    // this.setupContract();
  }

  async connect() {
    this.account_loading = true;
    console.log('Connecting');
    return new Promise((resolve, reject) => {
      let ethereum = (window as any).ethereum;
      if (typeof ethereum !== 'undefined') {
        console.log('MetaMask is available', ethereum);
      }
      if (ethereum) {
        try {
          ethereum
            .request({ method: 'eth_requestAccounts' })
            .then(async (address: any) => {
              console.log('GET ADDRESS', address);

              this._accounts = address;
              if (!this.web3) {
                this.provider = <any | null>await detectEthereumProvider();
                if (!this.provider) {
                  this.disconnect();
                  return;
                } else console.log('PROVIDER', this.provider);

                this.web3 = new Web3(this.provider);
              }

              // this._accounts = await this.web3.eth.getAccounts();
              // this._balance = await this.web3.eth.getBalance(this._accounts[0]);

              this.connected_chain = await this.web3.eth.getChainId();
              // console.log(net);

              this.provider.on('networkChanged', (id: any) => {
                if (id != this.connected_chain) {
                  this.connected_chain = id;
                  this.setupContract();
                }
                this.emit('networkChanged');
              });

              this.provider.on('accountsChanged', async (accounts: any) => {
                this._accounts = accounts;

                if (accounts.length > 0) {
                  this._balance = await this.web3!.eth.getBalance(
                    this._accounts[0]
                  );
                  this.subscribeLogs(this._accounts[0]);
                  this.setupContract();
                  this.emit('connected');
                } else {
                  this.emit('disconnect');
                }
              });

              this.provider.on('chainChanged', () => {
                this.web3!.eth.net.getId().then(async (id) => {
                  this.connected_chain = id;
                  this._balance = await this.web3!.eth.getBalance(
                    this._accounts[0]
                  );
                  this.setupContract();
                });
              });

              this.provider.on('disconnect', (code: number, reason: string) => {
                // console.log(code, reason);
                this.disconnect();
              });

              console.log('AFTER PROVIDER EVENTS');

              // this.provider.on('message', (message: any) => {
              //     console.log("message", message);
              // })
              if (this.accounts.length) this.emit('connected');
              // this.setupContract();
              this.contract.options.from = this._accounts[0];
              this.account_loading = false;
              resolve(address[0]);
            });
        } catch (error) {
          // User denied account access...
          console.log('User denied account access');
          this.disconnect();
        }
      } else {
        this.account_loading = false;
        this.showToast({
          header: 'Error',
          message:
            "We can't seem to find an Ethereum provider on your browser. Make sure you have MetaMask installed and running.",
          duration: 10000,
        });
      }
    });
  }

  async disconnect() {
    console.log('DISCONNECT');

    this._accounts = [];
    this.permissions = {
      is_admin: false,
      is_pauser: false,
    };
    this.emit('disconnect');
  }

  subscribeLogs(address: string) {
    this.logSubscription = this.web3!.eth.subscribe(
      'newBlockHeaders',
      (err, blockheader) => {
        // console.log('LOGS', data);
        // if(err) console.log(err);
      }
    ).on('data', (blockHeader) => {
      // console.log(blockHeader);
      // this.readBlock(blockHeader.number);
    });
  }

  async setupContract() {
    this.contract_loading = true;
    this.web3_error = undefined;
    console.log('Setting up Contract');

    if (!this.web3) {
      this.provider = <any | null>await detectEthereumProvider();
      if (!this.provider) {
        this.disconnect();
        return;
      } else console.log('PROVIDER', this.provider);

      this.web3 = new Web3(this.provider);
      this.connected_chain = await this.web3.eth.getChainId();
    }
    console.log('Web3 Init');

    // if(!this.accounts.length) {
    //     this.web3_error = "Error getting account";
    //     this.contract_loading = false;
    //     return;
    // }
    if (!environment.contract_address || environment.contract_address == '') {
      this.web3_error = 'Contract Address not set';
      this.contract_loading = false;
      return;
    }
    console.log('Address ready');

    let abi: AbiItem | AbiItem[] = <any>abijson.abi;
    console.log('ABI Loaded');

    this.contract = new this.web3.eth.Contract(
      abi,
      environment.contract_address
    );
    // this.contract.options.from = this._accounts[0]
    console.log('Contract', this.contract);
    this.emit('contract-set');

    this.loadPermission();
    this.getContractState();

    this.contract_loading = false;
  }

  async loadPermission() {
    this.permission_loading = true;
    console.log('Loading Permissions');

    this.permission_loading = false;
  }

  async getContractState() {
    console.log('Getting Contract State');

    try {
      // this.public_sale_active = await this.getContractValue<boolean>('isSalePublic');
      // this.sale_paused = await this.getContractValue<boolean>('paused');
      // this.minted = await this.getContractValue<number>('totalSupply');
      // this.max = await this.getContractValue<number>('maxSupply');
      // this.maxZodiac = await this.getContractValue<number>('maxZodiacSupply');
      // this._price = await this.getContractValue<number>('price');
    } catch (err) {
      console.log('Error getting contract state', err);
    }
  }

  getContractValue<T>(view: string, ...args: any[]): Promise<T> {
    return new Promise(async (resolve, reject) => {
      if (this.contract) {
        try {
          let data: T = await this.contract.methods[view](...args).call();
          // console.log(data);
          resolve(data);
        } catch (err) {
          reject(err);
        }
      } else {
        reject('Contract not set');
      }
    });
  }

  contractABI(): Promise<AbiItem | AbiItem[]> {
    return new Promise(async (resolve, reject) => {
      if (!this.contract_json) {
        // let json = await this.http.get<ContractJson>(`https://ipfs.io/ipfs/${environment.contract_json_cid}`).toPromise().catch((err) => {
        //     console.log('Error loading JSON', err);
        // })
        let json = abijson as any;
        if (!json) {
          reject('Error loading Contract ABI');
          return;
        }
        this.contract_json = json;
        resolve(json.abi);
      } else {
        resolve(this.contract_json.abi);
      }
    });
  }

  getMetaData<T>(url: string): Promise<T> {
    return new Promise(async (resolve, reject) => {
      let json = await this.http
        .get<T>(url)
        .toPromise()
        .catch((err) => {
          console.log('Error loading JSON', err);
        });
      if (!json) {
        reject('Error loading MetaData');
        return;
      }
      resolve(json);
    });
  }

  async showToast(data: {
    header: string;
    message?: string;
    icon?: string;
    link?: string;
    linkText?: string;
    duration?: number;
    color?: string;
  }) {
    let msg = data.message
      ? `${data.header} : ${data.message}`
      : `${data.header}`;

    let toast = this.toast.open(msg, data.linkText ? data.linkText : 'Ok', {
      duration: data.duration ? data.duration : 4000,
    });

    return toast;
  }

  async mint(id: number, amount: number, value?: string) {
    return value
      ? this.contract!.methods.mint(id, amount).send({ value })
      : this.contract!.methods.mint(id, amount).send({ value: 0 });
  }

  openExplorer(trx: string) {
    if (this.connected_chain && network_explorer[this.connected_chain]) {
      this.window.open(
        network_explorer[this.connected_chain] + 'tx/' + trx,
        '_blank'
      );
    }
  }

  on(event: string) {
    let sub = new Subject();
    if (this.events[event] && this.events[event].length)
      this.events[event].push(sub);
    else this.events[event] = [sub];
    return sub;
  }
  emit(event: string, data?: any) {
    if (this.events[event]) for (let ev of this.events[event]) ev.next(data);
  }
}

interface ContractJson {
  _format: string;
  contractName: string;
  souceName: string;
  abi: AbiItem | AbiItem[];
  bytecode: string;
  deployedBytecode: string;
  linkReference: any;
  deployedLinkReferences: any;
}

export interface Signature {
  message: string;
  messageHash: string;
  v: string;
  r: string;
  s: string;
  signature: string;
}
