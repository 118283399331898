<div id="nav">
  <img
    class="wire-icon-mobile"
    src="../assets/WIRE_Icon_Blue_min.png"
    height="30px"
  />
  <button class="btn wire-btn-outline wire-mobile-menu-trigger">
    <fa-icon [icon]="['fas', 'bars']"></fa-icon>
  </button>
  <div class="wire-nav-links">
    <button class="btn wire-btn-primary wire-mobile-menu-trigger">
      <fa-icon [icon]="['fas', 'x']"></fa-icon>
    </button>
    <img src="../assets/WIRE_Icon_Blue_min.png" height="30px" />
    <div class="wire-node-buttons">
      <button
        class="btn wire-btn-primary-nav wire-tier-trigger"
        data-tier="t1"
        (click)="onSelect(0)"
      >
        T1 Node
      </button>
      <button
        class="btn wire-btn-primary-nav wire-tier-trigger"
        data-tier="t2"
        (click)="onSelect(1)"
      >
        T2 Node
      </button>
      <button
        class="btn wire-btn-primary-nav wire-tier-trigger"
        data-tier="t3"
        (click)="onSelect(2)"
      >
        T3 Node
      </button>
      <button
        class="btn wire-btn-primary-nav wire-tier-trigger disabled"
        data-tier="t3"
      >
        T4 Node
      </button>
      <button
        class="btn wire-btn-primary-nav wire-tier-trigger disabled"
        data-tier="t3"
      >
        T5 Node
      </button>
    </div>
    <div
      style="
        display: flex;
        justify-content: center;
        align-items: center;
        height: 4em;
        width: auto;
      "
    >
      <button class="btn wire-btn-outline theme-switcher">
        <fa-icon class="dark" [icon]="['fas', 'moon']"></fa-icon
        ><fa-icon class="light" [icon]="['fas', 'sun']"></fa-icon>
      </button>
      <button
        class="btn wire-btn-outline wire-btn-wallet"
        *ngIf="!connected"
        (click)="onConnect()"
      >
        Connect Wallet
      </button>
      <button class="btn wire-btn-outline wire-btn-wallet" *ngIf="connected">
        Connected: <b>{{ address }}</b>
      </button>
    </div>
  </div>
</div>

<div id="hero" class="container-fluid">
  <div
    class="row"
    data-tier="t3"
    [ngsReveal]="{ reset: true, scale: 1, distance: '15px' }"
  >
    <h1>T3</h1>
    <div class="wire-hero-card"><img src="../assets/t3-hero.png" /></div>
  </div>
  <div
    class="row"
    data-tier="t2"
    [ngsReveal]="{ reset: true, scale: 1, distance: '15px' }"
  >
    <h1>T2</h1>
    <div class="wire-hero-card"><img src="../assets/t2-hero.png" /></div>
  </div>
  <div
    class="row"
    data-tier="t1"
    [ngsReveal]="{ reset: true, scale: 1, distance: '15px' }"
  >
    <h1>T1</h1>
    <div class="wire-hero-card"><img src="../assets/t1-hero.png" /></div>
  </div>
</div>

<div id="page-content" data-tier="t3">
  <div
    class="container theme-dark"
    [ngsRevealSet]="{ reset: true, scale: 1, distance: '5px' }"
    [ngsSelector]="'.reveal'"
  >
    <div class="row justify-content-center text-center">
      <div class="col-lg-8 col-11 wire-card reveall">
        <h1>Premium Citizenship.</h1>
        <h4>
          Being a citizen of Wire not only looks good,<br />it does good for you
          and the network.
        </h4>
      </div>
    </div>
    <div class="wire-divider"></div>
    <div class="row justify-content-center align-items-center">
      <div class="col-lg-5 col-11 wire-card primary reveall">
        <h2>All gas, no fees.</h2>
        <p>
          As a premium user of Wire, you get unlimited resource usage, with no
          gas fees.
        </p>
      </div>
      <div class="col-lg-1 wire-row-gutter"></div>
      <div class="col-lg-5 col-11 reveall">
        <img src="../assets/t3-back.png" width="100%" />
      </div>
    </div>
  </div>
  <div class="wire-divider"></div>
  <div
    class="container"
    [ngsReveal]="{ reset: true, scale: 1, distance: '15px' }"
  >
    <div class="row justify-content-center">
      <div
        class="col-8 col-11 justify-content-center text-center"
        [ngsReveal]="{ reset: true, scale: 1, distance: '15px' }"
      >
        <h1>The platinum card of crypto.</h1>
        <p>
          The T3 Wire Node NFT grants you unlimited access to the network's
          resources.
        </p>
      </div>
      <div class="wire-divider evenless"></div>
    </div>
    <div
      class="row justify-content-center"
      [ngsRevealSet]="{ reset: true, scale: 1, distance: '5px' }"
      [ngsInterval]="150"
      [ngsSelector]="'.reveal'"
    >
      <div class="col-lg-3 col-11 reveall">
        <img src="../assets/t3-feature1.png" width="100%" />
      </div>
      <div class="col-lg-3 col-11 reveall">
        <img src="../assets/t3-feature2.png" width="100%" />
      </div>
      <div class="col-lg-3 col-11 reveall">
        <img src="../assets/t3-feature3.png" width="100%" />
      </div>
    </div>
  </div>
  <div class="wire-divider"></div>
  <div class="container-fluid wire-section-secondary">
    <div class="row justify-content-center">
      <div class="wire-divider"></div>
      <div class="col-lg-4 col-11 video-container">
        <video class="card-video" playsinline autoplay loop muted>
          <source src="../assets/Tier3NodeFinal.webm" type="video/webm" />
          <source src="../assets/Tier3NodeFinal.mp4" type="video/mp4" />
        </video>
      </div>
      <div class="col-lg-1 wire-row-gutter"></div>
      <div
        class="col-lg-4 col-11 wire-product-info"
        [ngsRevealSet]="{ reset: true, scale: 1, distance: '5px' }"
        [ngsInterval]="150"
        [ngsSelector]="'.reveal'"
      >
        <h2 class="reveall d-flex justify-content-between">
          Tier 3 Node
          <!-- <b *ngIf="selectedTier"></b>
            {{ selectedTier?.totalSupply }}/{{ selectedTier?.maxSupply }}</b
          > -->
        </h2>
        <p class="reveall">
          As a holder of a Wire T3 Node, you get premium membership access with
          no gas fees on the network.
        </p>
        <div class="wire-product-specs reveall">
          <ul>
            <li>Unlimited usage of the network, with no gas fees.</li>
            <li>Access to 0.00045% of the network's resources ($INV).</li>
            <li>Awarded back 100k $WIRE over three years.</li>
          </ul>
        </div>

        <div
          class="wire-btn-buy"
          *ngIf="assets.includes(3) && connected"
          (click)="mint()"
        >
          <div class="wire-btn-buy-half">
            <span class="wire-btn-buy-title">Tier 3 Node</span>
            <span class="wire-btn-buy-supply"
              >{{ tiers[2].maxSupply - tiers[2].totalSupply }} Nodes
              Remaining</span
            >
          </div>
          <div class="wire-btn-buy-half">
            <span class="wire-btn-buy-eth"
              >{{ (+tiers[2].ethPrice).toFixed(4) }} ETH</span
            >
            <span class="wire-btn-buy-usd"
              >{{ +tiers[2].price | usd: "comma" }} USD</span
            >
          </div>
        </div>

        <!-- <h3 class="reveal mb-3" style="font-weight: bold;" *ngIf="tiers[0] && tiers[0].ethPrice">
          {{ (+tiers[0].ethPrice).toFixed(4) }} ETH
        </h3>
        <h3 class="reveal mb-3" *ngIf="tiers[0]">
          {{ +tiers[0].price | usd : 'comma' }} {{ tiers[0].priceType == '0' ? 'ETH' : 'USD' }}
        </h3> -->

        <div style="display: flex; flex-direction: column">
          <!-- <button
            class="btn wire-btn-primary reveall"
            *ngIf="assets.includes(1) && connected"
            (click)="mint()"
            style="flex: 1; margin: 8px"
          >
            Buy Tier 1 Node
          </button> -->

          <button
            class="btn wire-btn-primary reveall"
            *ngIf="!assets.includes(1) && connected"
            style="flex: 1; margin-right: 0px"
          >
            Unavailable
          </button>
          <button
            class="btn wire-btn-outline reveall"
            *ngIf="!connected"
            style="flex: 1; margin-right: 0px"
            (click)="onConnect()"
          >
            Connect Wallet
          </button>
          <button
            class="btn wire-btn-outline reveall"
            *ngIf="connected"
            style="flex: 1; margin-right: 0px"
          >
            Connected: <b>{{ address }}</b>
          </button>
        </div>
      </div>
      <div class="wire-divider"></div>
    </div>
  </div>
</div>

<div id="page-content" data-tier="t2">
  <div
    class="container theme-dark"
    [ngsRevealSet]="{ reset: true, scale: 1, distance: '5px' }"
    [ngsSelector]="'.reveal'"
  >
    <div class="row justify-content-center text-center">
      <div class="col-lg-8 col-11 wire-card reveall">
        <h1>The Lawmakers of Wire.</h1>
        <h4>
          Sure you can <i>use</i> the Wire Network,<br />but why not dictate
          it's direction?
        </h4>
      </div>
    </div>
    <div class="wire-divider"></div>
    <div class="row justify-content-center align-items-center">
      <div class="col-lg-5 col-11 wire-card primary reveall">
        <h2>With great power comes great responsibility.</h2>
        <p>
          As a lawmaker of Wire, you will be one of 80 members who votes on new
          proposals for the network.
        </p>
      </div>
      <div class="col-lg-1 wire-row-gutter"></div>
      <div class="col-lg-5 col-11 reveall">
        <img src="../assets/t2-back.png" width="100%" />
      </div>
    </div>
  </div>
  <div class="wire-divider"></div>
  <div
    class="container"
    [ngsReveal]="{ reset: true, scale: 1, distance: '15px' }"
  >
    <div class="row justify-content-center">
      <div
        class="col-8 col-11 justify-content-center text-center"
        [ngsReveal]="{ reset: true, scale: 1, distance: '15px' }"
      >
        <h1>The voice of the people.</h1>
        <p>
          The T2 Wire Node NFT grants you the power to vote on the future of the
          network.
        </p>
      </div>
      <div class="wire-divider evenless"></div>
    </div>
    <div
      class="row justify-content-center"
      [ngsRevealSet]="{ reset: true, scale: 1, distance: '5px' }"
      [ngsInterval]="150"
      [ngsSelector]="'.reveal'"
    >
      <div class="col-lg-3 col-11 reveall">
        <img src="../assets/t2-feature1.png" width="100%" />
      </div>
      <div class="col-lg-3 col-11 reveall">
        <img src="../assets/t2-feature2.png" width="100%" />
      </div>
      <div class="col-lg-3 col-11 reveall">
        <img src="../assets/t2-feature3.png" width="100%" />
      </div>
    </div>
  </div>
  <div class="wire-divider"></div>
  <div class="container-fluid wire-section-secondary">
    <div class="row justify-content-center">
      <div class="wire-divider"></div>
      <div class="col-lg-4 col-11 video-container">
        <video class="card-video" playsinline autoplay loop muted>
          <source src="../assets/Tier2NodeFinal.webm" type="video/webm" />
          <source src="../assets/Tier2NodeFinal.mp4" type="video/mp4" />
        </video>
      </div>
      <div class="col-lg-1 wire-row-gutter"></div>
      <div
        class="col-lg-4 col-11 wire-product-info"
        [ngsRevealSet]="{ reset: true, scale: 1, distance: '5px' }"
        [ngsInterval]="150"
        [ngsSelector]="'.reveal'"
      >
        <h2 class="reveal d-flex justify-content-between">Tier 2 Node</h2>
        <p class="reveal">
          As a T2 Node owner, you're responsible for voting on which proposals
          get moved up to the governing board of T1 holders.
        </p>
        <div class="wire-product-specs reveall">
          <ul>
            <li>Vote on which proposals deserve attention.</li>
            <li>Whitelist contracts of your choice.</li>
            <li>Access to 0.15% of the network's resources ($PWR, $INV).</li>
            <li>Awarded back 1m $WIRE over two years.</li>
          </ul>
        </div>

        <div
          class="wire-btn-buy"
          *ngIf="assets.includes(2) && connected"
          (click)="mint()"
        >
          <div class="wire-btn-buy-half">
            <span class="wire-btn-buy-title">Tier 2 Node</span>
            <span class="wire-btn-buy-supply"
              >{{ tiers[1].maxSupply - tiers[0].totalSupply }} Nodes
              Remaining</span
            >
          </div>
          <div class="wire-btn-buy-half">
            <span class="wire-btn-buy-eth"
              >{{ (+tiers[1].ethPrice).toFixed(4) }} ETH</span
            >
            <span class="wire-btn-buy-usd"
              >{{ +tiers[1].price | usd: "comma" }} USD</span
            >
          </div>
        </div>

        <!-- <h3 class="reveal mb-3" style="font-weight: bold;" *ngIf="tiers[0] && tiers[0].ethPrice">
          {{ (+tiers[0].ethPrice).toFixed(4) }} ETH
        </h3>
        <h3 class="reveal mb-3" *ngIf="tiers[0]">
          {{ +tiers[0].price | usd : 'comma' }} {{ tiers[0].priceType == '0' ? 'ETH' : 'USD' }}
        </h3> -->

        <div style="display: flex; flex-direction: column">
          <!-- <button class="btn wire-btn-primary reveall" *ngIf="assets.includes(2)  && connected" (click)="mint()" style="flex: 1; margin: 8px;">Buy Tier 2 Node</button> -->
          <button
            class="btn wire-btn-primary reveall"
            *ngIf="!assets.includes(2) && connected"
            style="flex: 1; margin-right: 0px"
          >
            Unavailable
          </button>
          <button
            class="btn wire-btn-outline reveall"
            *ngIf="!connected"
            style="flex: 1; margin-right: 0px"
            (click)="onConnect()"
          >
            Connect Wallet
          </button>
          <button
            class="btn wire-btn-outline reveall"
            *ngIf="connected"
            style="flex: 1; margin-right: 0px"
          >
            Connected: <b>{{ address }}</b>
          </button>
        </div>
      </div>
      <div class="wire-divider"></div>
    </div>
  </div>
</div>

<div id="page-content" data-tier="t1">
  <div
    class="container theme-dark"
    [ngsRevealSet]="{ reset: true, scale: 1, distance: '5px' }"
    [ngsSelector]="'.reveal'"
  >
    <div class="row justify-content-center text-center">
      <div class="col-lg-8 col-11 wire-card reveall">
        <h1>Next-Gen Governance.</h1>
        <h4>
          Sure you can <i>use</i> the Wire Network,<br />but why not
          <i>run</i> the network?
        </h4>
      </div>
    </div>
    <div class="wire-divider"></div>
    <div class="row justify-content-center align-items-center">
      <div class="col-lg-5 col-11 wire-card primary reveall">
        <h2>Consensus starts with you.</h2>
        <p>
          As a Governor of Wire, you will be among the 21 appointed members to
          maintain consensus on the network.
        </p>
      </div>
      <div class="col-lg-1 wire-row-gutter"></div>
      <div class="col-lg-5 col-11 reveall">
        <img src="../assets/t1-back.png" width="100%" />
      </div>
    </div>
  </div>
  <div class="wire-divider"></div>
  <div
    class="container"
    [ngsReveal]="{ reset: true, scale: 1, distance: '15px' }"
  >
    <div class="row justify-content-center">
      <div
        class="col-8 col-11 justify-content-center text-center"
        [ngsReveal]="{ reset: true, scale: 1, distance: '15px' }"
      >
        <h1>The premiere membership token.</h1>
        <p>
          These highly-scarce Wire Node NFTs will prove your elite-status as a
          governing member of the network.
        </p>
      </div>
      <div class="wire-divider evenless"></div>
    </div>
    <div
      class="row justify-content-center"
      [ngsRevealSet]="{ reset: true, scale: 1, distance: '5px' }"
      [ngsInterval]="150"
      [ngsSelector]="'.reveal'"
    >
      <div class="col-lg-3 col-11 reveall">
        <img src="../assets/t1-feature1.png" width="100%" />
      </div>
      <div class="col-lg-3 col-11 reveall">
        <img src="../assets/t1-feature2.png" width="100%" />
      </div>
      <div class="col-lg-3 col-11 reveall">
        <img src="../assets/t1-feature3.png" width="100%" />
      </div>
    </div>
  </div>
  <div class="wire-divider"></div>
  <div class="container-fluid wire-section-secondary">
    <div class="row justify-content-center">
      <div class="wire-divider"></div>
      <div class="col-lg-4 col-11 video-container">
        <video class="card-video" playsinline autoplay loop muted>
          <source src="../assets/Tier1NodeFinal.webm" type="video/webm" />
          <source src="../assets/Tier1NodeFinal.mp4" type="video/mp4" />
        </video>
      </div>
      <div class="col-lg-1 wire-row-gutter"></div>
      <div
        class="col-lg-4 col-11 wire-product-info"
        [ngsRevealSet]="{ reset: true, scale: 1, distance: '5px' }"
        [ngsInterval]="150"
        [ngsSelector]="'.reveal'"
      >
        <h2 class="reveal d-flex justify-content-between">Tier 1 Node</h2>
        <p class="reveal">
          As one of the 21 members of Wire's governing board, you will be coming
          to a consensus on proposals that determine the future state of the
          network.
        </p>
        <div class="wire-product-specs reveall">
          <ul>
            <li>Appoint future candidates upon network expansion.</li>
            <li>Pass or deny proposals for the network.</li>
            <li>Whitelist contracts of your choice.</li>
            <li>Access to 4% of the network's resources ($PWR, $INV).</li>
            <li>Rewarded back 7.5m $WIRE over one year.</li>
          </ul>
        </div>

        <div
          class="wire-btn-buy"
          *ngIf="assets.includes(1) && connected"
          (click)="mint()"
        >
          <div class="wire-btn-buy-half">
            <span class="wire-btn-buy-title">Tier 1 Node</span>
            <span class="wire-btn-buy-supply"
              >{{ tiers[0].maxSupply - 10 }} Nodes Remaining</span
            >
          </div>
          <div class="wire-btn-buy-half">
            <span class="wire-btn-buy-eth"
              >{{ (+tiers[0].ethPrice).toFixed(4) }} ETH</span
            >
            <span class="wire-btn-buy-usd"
              >{{ +tiers[0].price | usd: "comma" }} USD</span
            >
          </div>
        </div>

        <!-- <h3 class="reveal mb-3" style="font-weight: bold;" *ngIf="tiers[0] && tiers[0].ethPrice">
          {{ (+tiers[0].ethPrice).toFixed(4) }} ETH
        </h3>
        <h3 class="reveal mb-3" *ngIf="tiers[0]">
          {{ +tiers[0].price | usd : 'comma' }} {{ tiers[0].priceType == '0' ? 'ETH' : 'USD' }}
        </h3> -->

        <div style="display: flex; flex-direction: column">
          <!-- <button
            class="btn wire-btn-primary reveall"
            *ngIf="assets.includes(1) && connected"
            (click)="mint()"
            style="flex: 1; margin: 8px"
          >
            Buy Tier 1 Node
          </button> -->

          <button
            class="btn wire-btn-primary reveall"
            *ngIf="!assets.includes(1) && connected"
            style="flex: 1; margin-right: 0px"
          >
            Unavailable
          </button>
          <button
            class="btn wire-btn-outline reveall"
            *ngIf="!connected"
            style="flex: 1; margin-right: 0px"
            (click)="onConnect()"
          >
            Connect Wallet
          </button>
          <button
            class="btn wire-btn-outline reveall"
            *ngIf="connected"
            style="flex: 1; margin-right: 0px"
          >
            Connected: <b>{{ address }}</b>
          </button>
        </div>
      </div>
      <div class="wire-divider"></div>
    </div>
  </div>
</div>

<div id="footer">
  <div class="container-fluid">
    <div class="row align-items-center justify-content-center">
      <div
        class="col-11"
        style="
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
        "
      >
        <img src="../assets/WIRE_Icon_Blue_min.png" height="25px" />
        <p style="font-size: 1em; margin-left: 1em; margin-bottom: 0">
          ©2022 Wire Foundation - All Rights Reserved.
        </p>
      </div>
    </div>
  </div>
</div>
