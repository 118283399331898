import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AlertButton, AlertOptions } from "src/_services/alert.service";

@Component({
    templateUrl: 'alert.component.html',
    styleUrls: ['alert.component.scss']
})
export class AlertComponent{
    get header(): string | undefined {
        return this.data.header
    }

    get message(): string | undefined {
        return this.data.message
    }

    get buttons(): AlertButton[] | undefined {
        return this.data.buttons
    }

    constructor(
        public dialogRef: MatDialogRef<AlertComponent>,
        @Inject(MAT_DIALOG_DATA) public data: AlertOptions
    ) {

    }

    handleButtonClick(button: AlertButton) {
        if(button.handler) {
            button.handler();
            this.dialogRef.close();
        } else if(button.role) {
            switch(button.role) {
                case 'cancel':
                    this.dialogRef.close(false);
                    break;
                case 'confirm':
                    this.dialogRef.close(true);
                    break;
                default:
                    this.dialogRef.close(true);
            }
        } else {
            this.dialogRef.close(true);
        }
    }
}