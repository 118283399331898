import { Pipe } from "@angular/core";

@Pipe({
    name: 'usd'
})
export class USDPipe {
    transform(value?: number, mode: string = 'comma', precision: string = '0') : string {
        let precInt = parseInt(precision);
        if(!value) return '$ 0.00';
        switch(mode) {
            case 'comma':
                return '$' + this.toCommas(value, precInt);
            case 'short':
                return this.toShorthand(value);
            case 'shorter':
                return this.toShorthand(value, true);
            default: 
                return this.toCommas(value);
        }
    }

    toCommas(value: number, precision?: number) : string {
        let [int, dec] = value.toString().split('.');
        if(precision && dec) dec = dec.substr(0, precision); 
        return int.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (dec ? '.' + dec : '');
    }

    toShorthand(value: number, shorter = false): string {
        let newVal = value;
        let symbol = '';
        if(value > 999999999999) {
            // trillion 
            newVal = Math.floor(value / 10000000000) / 100;
            symbol = shorter ? 'T' : 'trillion';
        } else if(value > 999999999) {
            // billion
            newVal = Math.floor(value / 10000000) / 100;
            symbol = shorter ? 'B' : 'billion';
        } else if(value > 999999) {
            // million
            newVal = Math.floor(value / 10000) / 100;
            symbol = shorter ? 'M' : 'million';
        }

        let [ int, dec ] = newVal.toString().split('.');
        let reAssembled = this.toCommas(parseInt(int)) + (dec ? '.' + dec : '');
        return `$${reAssembled} ${symbol}`;
    }
}